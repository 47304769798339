<template>
  <app-modal title="Credit pack" @submit="onSubmit">
    <hubspot-deal-line-item-widget
      v-model:deal-id="form.deal_id"
      v-model:line-item-ids="form.line_item_ids"
      :max-line-items="1"
      required-deal-id
      required-line-item-ids
      @select-line-items="onLineItemsSelect"
    />

    <div class="grid grid-cols-3 items-start gap-2">
      <app-form-input-text
        v-model.number="form.price!.amount"
        data-cy-price
        label="Price"
        readonly
        required
        type="number"
      >
        <template #append-inner> € </template>
      </app-form-input-text>
      <app-form-input-text
        v-model.number="form.credit_capacity"
        label="Credit total capacity"
        readonly
        required
        type="number"
      />
      <app-form-input-text
        v-model.number="form.credit_used_count"
        data-cy-credit-used-count
        label="Already used credits"
        required
        type="number"
      />
    </div>

    <app-form-input-datetime
      v-model="form.validity_end_date"
      label="Validity end date"
      required
      type="date"
    />
  </app-modal>
</template>

<script lang="ts" setup>
import type { HubspotLineItem } from "@pollen/core/model/hubspot.model";
import { addYears } from "date-fns";

import type {
  CreditPackCreationRequest,
  CreditPackView,
} from "~~/model/credit-pack.model";

const properties = defineProps<{
  creditPack?: CreditPackView;
}>();

const form = reactive<CreditPackCreationRequest>({
  price: {
    amount: properties.creditPack?.price?.amount as unknown as number,
    currency: "EUR",
  },
  credit_capacity: properties.creditPack?.credit_capacity as unknown as number,
  credit_used_count: properties.creditPack
    ?.credit_used_count as unknown as number,
  deal_id: properties.creditPack?.deal?.deal_id as unknown as string,
  line_item_ids: properties.creditPack?.deal?.line_item_ids ?? [],
  validity_end_date:
    properties.creditPack?.validity_end_date ??
    addYears(new Date(), 1).toISOString(),
});

function onLineItemsSelect(items: HubspotLineItem[]) {
  form.price!.amount = items.reduce(
    (total, current) =>
      total + current.properties.price * current.properties.quantity,
    0,
  );
  form.credit_capacity = items.reduce(
    (total, current) =>
      total + current.properties.credit_value * current.properties.quantity,
    0,
  );
  form.credit_used_count = 0;
}

function onSubmit() {
  return useModal("creditPackEditionModal").close({
    confirmed: true,
    payload: form,
  });
}
</script>
